// Entry point for the build script in your package.json

// NOTE: The problem is that import statements get hoisted.
//       So any import statement that requires jquery will be hoisted to execute before the window object assignments.
//       This means that imported scripts that depend on jquery will execute before jquery is available.
import './jquery'

import Rails from '@rails/ujs'
Rails.start()

// import * as ActiveStorage from '@rails/activestorage'
// ActiveStorage.start()
import './channels'
import './controllers'

import 'bootstrap'
import '@fortawesome/fontawesome-free/js/all'
import 'bootstrap-select'
import 'bootstrap-notify'
import 'bootstrap4-toggle/js/bootstrap4-toggle.min'
import 'daterangepicker'

// import '@hotwired/turbo-rails'
import { Turbo } from '@hotwired/turbo-rails'
Turbo.session.drive = false // Turns Turbo Drive off by default.

function allRequire(context){
  // console.debug('[JS][application.js] context', context.keys())
  context.keys().forEach(context)
}
allRequire(require.context('./javascripts/user', true, /\.js$/))
allRequire(require.context('./javascripts/admin', true, /\.js$/))

// Uncomment to copy all static images under ./images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('./images', true)
// const imagePath = (name) => images(name, true)
require.context('./images', true)

import { page, tooltip, popover } from './javascripts/init'

$(function() {
  console.debug('[JS]', page.controllerPath(), page.controller(), page.action())

  // Prevent from submitting a form by hitting Enter (Allow enter key on textareas only)
  //   https://stackoverflow.com/questions/895171/prevent-users-from-submitting-a-form-by-hitting-enter
  $('form').on('keydown', ':input:not(textarea)', function(event) {
    // console.log({ key: event.key, code: event.code }, event.target.tagName, $(event.target).is('textarea'), page.prettyObject())
    if (page.controllerPath() === 'user_members/sessions') return true
    if (page.controllerPath() === 'users/sessions') return true

    if (event.key === 'Enter') {
      console.debug('[JS] disable enter in form')
      event.preventDefault()
    }
  })

  // bootstrap alert auto close on info/warning
  $('.alert.alert-success').delay(10000).slideUp(500, function() { // , .alert.alert-warning
    $('.alert.alert-info').alert('close')
  })

  // bootstrap tooltip auto hide
  $(document).on('shown.bs.tooltip', function (e) {
    setTimeout(function () {
      $(e.target).tooltip('hide')
    }, 10000)
  })

  console.debug('[JS] tooltip')
  tooltip.trigger()

  console.debug('[JS] popover')
  popover.trigger()

  $('textarea.expandable').on('click', function () {
    // console.debug('[chat] textarea expandable clicked')
    this.setAttribute('style', `${this.getAttribute('style')} height: ${this.scrollHeight}px; overflow-y: hidden;`);
  })
})
