import { Controller } from '@hotwired/stimulus'
import { randomString } from './functions/util'

export default class extends Controller {
  static get values() {
    return { id: String }
  }
  static get targets() {
    return ['valueForm']
  }

  remove(event) {
    // Detect the target valueForm to check if which valueForm targets (plural) contains the element trigering this event.
    const targetValueForm = this.valueFormTargets.find(el => el.contains(event.currentTarget))
    if (!targetValueForm) alert('No form to remove found') // something wrong

    if (this.valueFormTargets.length > 1) {
      if (window.confirm(gon.translations.delete_confirm)) {
        $(event.currentTarget).tooltip('hide')
        targetValueForm.remove()
      }
    } else {
      alert(gon.translations.delete_option_one)
    }
  }

  add() {
    const itemElement = this.valueFormTarget

    const newItem = itemElement.cloneNode(true) // true: copy elements nested inside it
    newItem.id = this.idValue + '-' + randomString()

    const newItemInput = newItem.querySelector('input')
    newItemInput.value = ''
    newItemInput.readOnly = false

    itemElement.parentNode.append(newItem)
  }
}
