import { Controller } from '@hotwired/stimulus'
import { useHover } from 'stimulus-use'

export default class extends Controller {
  static get targets() { return ['attached'] }
  static get classes() { return ['attached'] }

  connect() {
    useHover(this, { element: this.element })
  }

  mouseEnter() {
    // console.debug('mouse enter', this.attachedTarget, this.attachedClass)
    if (this.attachedTarget.classList.contains(this.attachedClass)) {
      // console.debug('[chat][toggle_class_on_hover] already shown', this.attachedClass, this.attachedTarget.classList)
      return
    }
    this.attachedTarget.classList.add(this.attachedClass)
    // this.load()
    this.dispatch('mouseEnter')
  }

  mouseLeave() {
    // console.debug('mouse leave', this.attachedTarget, this.attachedClass)
    this.attachedTarget.classList.remove(this.attachedClass)
  }
}
