import { Controller } from '@hotwired/stimulus'
import Chart from 'chart.js/auto'
import Color from 'color'

function transparentize(value, opacity) {
  var alpha = opacity === undefined ? 0.5 : 1 - opacity
  return Color(value).alpha(alpha).toString()
}

export default class extends Controller {
  static get values() {
    return {
      profileUuid: String,
    }
  }

  connect() {
    console.debug('[chat][js] connect', this.identifier)
    // this.element['$scenarios'] = this
    this.drawComboChart()
  }

  // {{{
  async drawComboChart() {
    const fechedData = await this._fetchData(this.profileUuidValue)
    // console.debug('[chat] daily_cvr_data', fechedData)

    const data = {
      datasets: [{
        type: 'bar',
        label: 'cvr',
        data: fechedData['data']['cvr'],
        yAxisID: 'left',
        // Styling
        barPercentage: 0.75,
        backgroundColor: transparentize('green', 0.5),
        borderColor: 'green',
      }, {
        type: 'line',
        label: 'access',
        data: fechedData['data']['access'],
        yAxisID: 'right',
        // Point Styling
        pointRadius: 0.3,
        // Line Styling
        fill: false,
        borderWidth: 1,
        borderColor: transparentize('blue', 0.5),
        tension: 0.5,
      }, {
        type: 'line',
        label: 'cv',
        data: fechedData['data']['cv'],
        yAxisID: 'right',
        // Point Styling
        pointRadius: 0.3,
        // Line Styling
        fill: false,
        borderWidth: 1,
        borderColor: transparentize('red', 0.5),
        tension: 0.5,
      }]
    }
    const options = {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false,
        },
      },
      interaction: {
        mode: 'index',
        intersect: true,
      },
      scales: {
        x: {
          // https://www.chartjs.org/docs/latest/axes/cartesian/category.html
          type: 'category',
          labels: fechedData['labels'],
          grid: { display: false },
          border: { display: true },
          ticks: {
            autoSkip: true,
            autoSkipPadding: 10, // *3 # Padding between the ticks on the horizontal axis
            maxRotation: 0,
            font: { size: 10, },
            padding: -3,
          },
        },
        left: {
          title: {
            display: true,
            text: 'cvr',
            font: { size: 10, },
            color: 'green',
          },
          grid: { display: false },
          border: { display: false },
          ticks: {
            font: { size: 10, },
            callback: function(value, _index, _values) {
              return value + '%';
            },
          },
          type: 'linear',
          display: true,
          position: 'left',
          // max: 30,
          min: 0,
        },
        right: {
          title: {
            display: true,
            text: 'access | cv',
            font: { size: 10, },
          },
          grid: { display: false },
          border: { display: false },
          ticks: {
            font: { size: 10, },
          },
          type: 'linear',
          display: true,
          position: 'right',
          min: 0,
        },
      },
    }

    // undefined at first
    if (this.chart) this.chart.destroy()

    const chart = new Chart(this.element, {
      data: data,
      options: options,
    })

    this.chart = chart
  }
  // }}}

  /*
   * {
   *   labels: [],
   *   data: { access: [], cv: [], cvr: [] },
   * }
   */
  // {{{
  async _fetchData(profileUUID) {
    const params = {
      profile_uuid: profileUUID,
    }
    const queryParams = new URLSearchParams(params)

    const response = await fetch('/user/api/profiles/daily_cvr_data?' + queryParams, {
      method: 'GET',
      mode: 'same-origin', // *cors, same-origin, no-cors
    })
    if (!response.ok) {
      console.error('[chat][js] daily_cvr_data error.', response)
      throw 'error'
    }
    const json = await response.json()
    return json
  }
  // }}}
}
