import { Controller } from '@hotwired/stimulus'
import hljs from 'highlight.js/lib/core'
import javascript from 'highlight.js/lib/languages/javascript'
import xml from 'highlight.js/lib/languages/xml'
import json from 'highlight.js/lib/languages/json'
import yaml from 'highlight.js/lib/languages/yaml'
import css from 'highlight.js/lib/languages/css'
import scss from 'highlight.js/lib/languages/scss'

const LANGUAGES = {
  xml: xml,
  html: xml,
  javascript: javascript,
  css: css,
  scss: scss,
  json: json,
  yaml: yaml,
}

export default class extends Controller {
  static get values() {
    return {
      lang: { type: String, default: 'xml' },
    }
  }

  connect() {
    // console.debug('[chat][js] connect', this.identifier)
    this.highlight()
  }

  highlight() {
    // console.debug('[chat][JS] highlight.js')
    // $('pre code').each(function (_index, block) { hljs.highlightElement(block) })
    // hljs.initHighlightingOnLoad()
    hljs.registerLanguage(this.langValue, LANGUAGES[this.langValue])
    hljs.highlightElement(this.element)
  }
}
