import { Controller } from '@hotwired/stimulus'
import { useThrottle } from 'stimulus-use'

export default class extends Controller {
  static get values() {
    return {
      url: { type: String, default: '' },
      useThrottle: { type: Boolean, default: false },
    }
  }
  static get targets() {
    return ['result']
  }
  static get throttles() {
    return ['load']
  }

  connect() {
    if (this.useThrottleValue) useThrottle(this, { wait: 10000 })
  }

  load() {
    if (!this.hasUrlValue) {
      console.error('[chat][js] No URL value.')
      throw '[chat] No URL value.'
    }

    fetch(this.urlValue)
      .then(response => response.text())
      .then(html => {
        this.resultTarget.innerHTML = html
      })
  }
}
