import { Controller } from '@hotwired/stimulus'
import { csrfToken } from './schema_editor/functions/util'
import Sortable from 'sortablejs'

export default class extends Controller {
  static get values() {
    return {
      apiEndpoint: String,
    }
  }

  connect() {
    console.debug('[chat][js] connect', this.identifier)
    // this.element.$sortable = this
    const options = {
      handle: '.sortable-handle',
      ghostClass: 'bg-secondary',
      animation: 150,
      fallbackOnBody: true,
      swapThreshold: 0.65,
      filter: '.ignore-sortable', // NOTE: selects are not open due to backwards compatibility of `preventOnFilter` condition.
      preventOnFilter: false,     //       https://github.com/SortableJS/Sortable/issues/1537
    }
    Sortable.create(this.element, options)
  }

  sort(event) {
    // console.debug('sortable', 'update', event)
    const payload = JSON.parse(
      JSON.stringify(event.params.payload).replace("$item_id", event.item.id)
    )
    const params = {
      payload: {
        ...payload,
        old_index: event.oldIndex,
        new_index: event.newIndex,
        // position_label: :first, :last, :up and :down,
      },
    }
    this.sort_by({ params: params })
  }

  sort_by({ params }) {
    // console.debug('sortable', 'params', params)
    const data = { ...params.payload }
    fetch(this.apiEndpointValue, {
      method: 'PUT',
      mode: 'same-origin', // no-cors, *cors, same-origin
      // cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      // credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        'X-CSRF-Token': csrfToken(),
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data),
    }).then(response => {
      if (!response.ok) {
        if (response.status === 401) {
          response.json().then(function(json) {
            console.error('[chat][js]', json.error.text)
            window.alert(json.error.text) // Unauthorized
          })
        } else {
          window.alert('Internal Server Error.')
        }
        throw response;
      }
      return response.json()
    }).then((json) => {
      console.debug('[chat][js] sort result', json)
      if (params.payload.position_label) location.reload()
    }).catch(error => {
      console.error('[chat][js] error.', error)
      location.reload()
    })
  }
}
