import { page, introJS, createSortable } from '../init'

$(function() {
  if (['templates', 'profiles'].includes(page.controller()) && ['edit', 'update'].includes(page.action())) {
    console.debug('scenarios_editor')

    console.debug('[JS] intro.js')
    introJS.onClick('#intro-start')

    console.debug('[JS] sortable')
    $("[data-sort='sortable']").each((_index, element) => createSortable(element))
  }
})
