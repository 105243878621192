import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect() {
    console.debug('[chat][js] connect', this.identifier)
    this.element.$debug = this
  }

  change(event) {
    console.debug('change', event.detail.value, event)
  }
}
