import { page } from '../../init'

$(function() {
  if (page.controller() === 'revisions') {
    // console.log('intro.js', gon.introjs_options)
    // introJS.onClick('#intro-start')
    if (page.action() === 'show') {
      console.debug('[JS] clipboard')
      const ClipboardJS = require('clipboard')
      const clipboard = new ClipboardJS('.clip-btn')
      clipboard.on('error', function(e) {
        console.error(e)
      })

      // console.debug('[JS] ace')
      // const editor = ace.edit('editor')
      // editor.session.setMode(new yamlMode.Mode())
    }
  }
})


