import { Controller } from '@hotwired/stimulus'
import { csrfToken } from './functions/util'

export default class extends Controller {
  connect() {
    console.debug('[chat][js] connect', this.identifier)
    // this.element['$scenarios'] = this
  }
  disconnect() { console.debug('[chat][js]', this.disconnect.name, this.identifier) }

  preview({ params: { action } }) {
    if (!gon.template_uuid) { console.error('readonly'); return; }

    // console.debug('params.action', action)
    switch (action) {
      case 'position-left':
        $('#smart-dialog').css('left', '12px')
        $('#smart-dialog').css('right', '')

        this._postByAjax()
        // this._postByFetch()
        break
      case 'position-right':
        $('#smart-dialog').css('left', '')
        $('#smart-dialog').css('right', '12px')

        this._postByAjax()
        // this._postByFetch()
        break
      case 'close':
        $('#smart-dialog').trigger('chat:close:force')
        break
      default:
        console.error(`No such a action '${action}'.`)
    }
  }

  // NOTE:
  // new FormData(formElement):
  //   Content-Type: multipart/form-data; boundary=----WebKitFormBoundaryhB0YCJI9REWad4kN
  //
  // new URLSearchParams(new FormData(formElement)):
  //   Content-Type: application/x-www-form-urlencoded
  //   The constructor argument to URLSearchParams was very new and had very limited support.
  //   https://stackoverflow.com/questions/46640024/how-do-i-post-form-data-with-fetch-api/46642899#46642899
  //
  // application/x-www-form-urlencoded or multipart/form-data?
  //   multipart/form-data (new FormData(formElement)) is for binary data (or a sized payload) to transmit,
  //   otherwise use application/x-www-form-urlencoded.
  //   https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
  // {{{
  _postByFetch() {
    fetch(`/user/api/templates/schema_editor/preview.json`, {
      method: 'PUT',
      mode: 'same-origin', // no-cors, *cors, same-origin
      // cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      // credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        'X-CSRF-Token': csrfToken(),
      },
      body: new URLSearchParams(new FormData(document.querySelector('form'))), // Content-Type: application/x-www-form-urlencoded
      // body: new FormData(document.querySelector('form')), // Content-Type: multipart/form-data;
    }).then(response => {
      if (!response.ok) {
        if (response.status === 401) {
          response.json().then(function(json) {
            console.error('[chat][js]', json.error.text)
            window.alert(json.error.text) // Unauthorized
          })
        } else {
          window.alert('Internal Server Error.')
        }
        throw response
      }
      return response.json()
    }).then((json) => {
      sessionStorage.setItem('chat:edit:settings', '__q_objt|' + JSON.stringify(json.settings))
      sessionStorage.setItem('chat:edit:scenarios', '__q_objt|' + JSON.stringify(json.scenarios))
      if (json.forms) sessionStorage.setItem('chat:edit:forms', '__q_objt|' + JSON.stringify(json.forms))
      if (json.js_keys) sessionStorage.setItem('chat:edit:js_keys', '__q_objt|' + JSON.stringify(json.js_keys))
      $(document).trigger('chat:reload:mode:preview')
    }).catch(error => {
      console.error('[chat][js] error.', error)
    })
  }
  // }}}

  // Content-Type: application/x-www-form-urlencoded; charset=UTF-8
  //
  // dataType: what kind of response to expect
  // contentType: HTTP header sent to the server, specifying a particular format.
  _postByAjax() {
    $.ajax({
      type: 'PUT',
      url: `/user/api/templates/schema_editor/preview.json`,
      data: $('form').serialize(),
      dataType: 'json', // json, text
      success: function(data) {
        // console.degbug('[chat] update-chat-preview', data)
        sessionStorage.setItem('chat:edit:settings', '__q_objt|' + JSON.stringify(data.settings))
        sessionStorage.setItem('chat:edit:scenarios', '__q_objt|' + JSON.stringify(data.scenarios))
        if (data.forms) sessionStorage.setItem('chat:edit:forms', '__q_objt|' + JSON.stringify(data.forms))
        if (data.js_keys) sessionStorage.setItem('chat:edit:js_keys', '__q_objt|' + JSON.stringify(data.js_keys))
        $(document).trigger('chat:reload:mode:preview')
      },
      error: function(data, textStatus, _errorThrown) {
        if (data.status === 401 || data.status === 400) {
          // 401 : Unauthorized
          // 400 : Bad Request
          const env = data.responseJSON.env
          const { text, detail } = data.responseJSON.error
          console.warn(
            `[chat][js] ${data.status}: ${data.statusText}`,
            '\nenv: ', env, '\ntext: ', text, '\ndetail: ', detail, '\n',
            data, '\n', textStatus
          )
          window.alert(text)
        } else {
          console.error(`[chat][js] ${data.status}: ${data.statusText}\n${data.responseText}`, data, textStatus)
          const text = data.responseText.split("\n")?.slice(0, 5)
          window.alert(`${data.status}: ${data.statusText}\n==============\n${text}`) // Internal Server Error.
        }
        // throw errorThrown
      }
    })
  }
}
