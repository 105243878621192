import { page } from '../../../init'

$(function() {
  if (page.controllerPath() === 'user/profiles/builds/preview_for_screenshot') {
    // NOTE: Set last scenario key to storage to load all the scenarios

    // storage key format to chat:scenario from scenario
    if (gon.chat_release_datetime >= 20220803) {
      sessionStorage.setItem('chat:scenario', '__q_strn|' + gon.chat_scenario_last_key)
    } else {
      sessionStorage.setItem('scenario', '__q_strn|' + gon.chat_scenario_last_key) // For old chat
    }

    page.afterChatLoaded(function() {
      // NOTE: Set chat settings by gon.chat_settings
      for (const chat_setting_key in gon.chat_settings) {
        console.debug('[chat][settings]', chat_setting_key, gon.chat_settings[chat_setting_key])
        window.chat.settings[chat_setting_key] = gon.chat_settings[chat_setting_key]
      }

      // NOTE: Update css style to view in full
      const fullHeight = $('#smart-dialog .layout-padding').height()
      $('#smart-dialog')
        .trigger('click')
        .css('max-height', 'unset')
        .css('position', 'absolute')
        .css('top', 0)
        .css('left', 0)
        .css('height', fullHeight)
        .find('.q-scrollarea')
          .css('position', 'absolute')
          .end()
        .find('.q-scrollarea')
          .css('position', 'unset')
          .css('contain', 'unset')
          .find('.q-scrollarea__container')
            .removeClass('relative-position')
            .end()

      const chatTargetId = window.chat.settings.targetId
      $('a[href$="' + chatTargetId + '"]')[0].click() // https://stackoverflow.com/a/21334234/3924859
    })
  }
})
