import { Controller } from '@hotwired/stimulus'
import { useThrottle } from 'stimulus-use'

export default class extends Controller {
  static get values() {
    return {
      url: { type: String, default: '' },
      useThrottle: { type: Boolean, default: false },
      formId: { type: String, default: '' },
    }
  }
  static get targets() {
    return ['result', 'loading']
  }
  static get throttles() {
    return ['load']
  }
  static get classes() {
    return ['loading']
  }

  connect() {
    if (this.useThrottleValue) useThrottle(this, { wait: 10000 })
  }

  load() {
    if (!this.hasUrlValue) {
      console.error('[chat][js] No URL value.')
      throw '[chat] No URL value.'
    }
    this.loadingTarget.classList.add(...this.loadingClasses)

    const formData = new FormData(document.getElementById(this.formIdValue))
    fetch(this.urlValue, {
      method: 'PUT',
      mode: 'same-origin', // no-cors, *cors, same-origin
      // cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      // credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        'X-CSRF-Token': document.querySelector("[name='csrf-token']").content,
      },
      body: formData,
    }).then(response => {
      if (!response.ok) {
        if (response.status === 401) {
          response.json().then(function(json) {
            console.error('[chat][js]', json.error.text)
            window.alert(json.error.text) // Unauthorized
          })
        } else {
          window.alert('Internal Server Error.')
        }
        throw response;
      }
      return response.text()
    }).then((html) => {
      this.resultTarget.innerHTML = html
      this.loadingTarget.classList.remove(...this.loadingClasses)
    }).catch(error => {
      console.error('[chat][js] error.', error)
      this.loadingTarget.classList.remove(...this.loadingClasses)
    })
  }
}
