import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static get values() {
    return {
      key: String,
      next: String
    }
  }
  static get targets() {
    return ['next']
  }

  connect() {
    console.debug('[chat][js]', this.connect.name, this.identifier)
    this.element['$scenario'] = this
  }
  disconnect() { console.debug('[chat][js]', this.disconnect.name, this.identifier) }

  remove(event) {
    function removeScenarioOptionFromNextSelectOptions(scenariosContainerNode, scenarioKey) {
      scenariosContainerNode.querySelectorAll('select[data-schema-key="scenarios:next"]').forEach(element => {
        element.querySelectorAll('option').forEach(optionElement => {
          if (optionElement.value === scenarioKey) optionElement.remove()
        })
      })
    }
    const scenariosContainerNode = this.element.parentNode
    if (this.element.$operations.removeItem(event)) removeScenarioOptionFromNextSelectOptions(scenariosContainerNode, this.keyValue)
  }

  changeNext(event) {
    this.nextValue = event.currentTarget.value
  }

  nextValueChanged() {
    this.nextTarget.textContent = '» ' + this.nextValue
  }
}
