import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static get values() {
    return {
      readOnly: { type: Boolean, default: true },
    }
  }
  static get targets() {
    return ['icon', 'formElement']
  }

  connect() {
    // console.debug('[chat][js] connect', this.identifier)
    // this.element.$toggleReadonly = this
  }

  toggle() {
    this.readOnlyValue = !this.readOnlyValue
  }

  // Called after:
  //  - the controller is initialize
  //  - any time its associated data attribute changes
  readOnlyValueChanged() {
    if (this.readOnlyValue) {
      this.iconTarget.innerText = 'lock'
      this.formElementTarget.setAttribute('readonly', '')
    } else {
      this.iconTarget.innerText = 'edit'
      this.formElementTarget.removeAttribute('readonly')
    }
  }
}
