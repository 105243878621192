import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    url: { type: String, default: '' },
  }
  static targets = ['results']

  connect() {
    console.debug('[chat][js] connect', this.identifier)
    this.#load()
  }

  #load() {
    if (!this.hasUrlValue) {
      console.error('[chat][js] No URL value.')
      throw '[chat] No URL value.'
    }

    fetch(this.urlValue)
      .then(response => response.json())
      .then(json => {
        // console.debug('[chat]', this.identifier, json, this.resultsTargets)
        this.resultsTargets.forEach(resultTarget => {
          const key = resultTarget.dataset.key
          resultTarget.textContent = json[key] || '-'
          // console.debug('[chat]', key, json[key])
        })
      })
  }
}
