import { page, bootstrapSelect } from '../init'

$(function() {
  if (page.controller() === 'profiles') {
    if (['index', 'new'].includes(page.action())) {
      console.debug('[JS] bootstrap-select')
      bootstrapSelect.trigger({ showSubtext: true })
    }

    if (page.action() === 'show') {
      $('.scrollable').click(function (_event) {
        const scenarioKey = $(this).data('targetScenarioKey')
        const scenarioMessageElement = document.querySelector('iframe.iframe').contentWindow.document.querySelector('.chat-scenario--' + scenarioKey + '--message')
        if (scenarioMessageElement) {
          const scenarioPositionInIframe =  scenarioMessageElement.offsetTop
          document.querySelector('.iframe').contentWindow.document.documentElement.scrollTop = scenarioPositionInIframe
        }
      })
    }
  }
})
