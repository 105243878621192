import { Controller } from '@hotwired/stimulus'
import { sortable, popover } from './functions/wrapper'
import { randomString, csrfToken, htmlToElement, animate } from './functions/util'

export default class extends Controller {
  static get values() {
    return {
      schemaKey: String,
      parentKeys: Array,
      nestedLevel: Number,
    }
  }
  static get targets() {
    return ['list']
  }

  connect() {
    // console.debug('[chat][js] connect', this.identifier)
    this.element.$formList = this
  }
  // disconnect() { console.debug('[chat][js] disconnect', this.identifier) }

  add({ params }) {
    if (!gon.template_uuid) { console.error('readonly'); return; }

    const targetListElement = this.listTarget
    const parentModelKey = params.parentModelKey
    const modelKey = params.modelKey

    const data = {
      schema_key: this.schemaKeyValue,
      parent_model_key: parentModelKey,
      model_key: modelKey,
      parent_keys: this.parentKeysValue,
      form_uuid: randomString(),
      nested_level: this.nestedLevelValue,
    }
    fetch('/user/api/templates/schema_editor/schema_form_item', {
      method: 'POST',
      mode: 'same-origin', // no-cors, *cors, same-origin
      // cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      // credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        'X-CSRF-Token': csrfToken(),
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data),
    }).then(response => {
      if (!response.ok) {
        if (response.status === 401) {
          response.json().then(function(json) {
            console.error('[chat][js]', json.error.text)
            window.alert(json.error.text) // Unauthorized
          })
        } else {
          window.alert('Internal Server Error.')
        }
        throw response;
      }
      return response.text()
    }).then((html) => {
      const template = htmlToElement(html)
      targetListElement.prepend(template)
      // targetListElement.insertAdjacentHTML('afterbegin', html);
      animate(template, 'fadeIn', 'slower')

      sortable.applyTo(template)
      popover.applyTo(template)
      this.dispatch('add')
    }).catch(error => {
      console.error('[chat][js] error.', error)
    })
  }
}
