import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static get values() {
    return {
      profileUuid: String,
    }
  }

  connect() {
    console.debug('[chat][js] connect', this.identifier)
    // this.element['$scenarios'] = this
    this.draw()
  }

  async draw() {
    const fechedText = await this._fetchData(this.profileUuidValue, true)
    // console.debug(fechedText)
    const div = document.createElement('div')
    div.insertAdjacentHTML('beforeend', fechedText);
    // const text = `d: ${fechedData.day.cvr}<br/>w: ${fechedData.week.cvr}<br/>m: ${fechedData.month.cvr}`
    // div.innerHTML = text
    $(div).find('[data-toggle="tooltip"]').tooltip()
    this.element.replaceChildren(div)
  }

  /*
   * {
   *   day: { access: 0, cv: 0, cvr: 0 },
   *   week: { access: 0, cv: 0, cvr: 0 },
   *   month: { access: 0, cv: 0, cvr: 0 },
   * }
   */
  // {{{
  async _fetchData(profileUUID) {
    const params = {
      profile_uuid: profileUUID,
    }
    const queryParams = new URLSearchParams(params)

    const response = await fetch('/user/api/profiles/avg_cvr_in_some_ranges.html?' + queryParams, {
      method: 'GET',
      mode: 'same-origin', // *cors, same-origin, no-cors
    })
    if (!response.ok) {
      console.error('[chat][js] avg_cvr_in_some_ranges error.', response)
      throw 'error'
    }
    const text = await response.text()
    return text
  }
  // }}}
}
