import { Controller } from '@hotwired/stimulus'
import { useIntersection } from 'stimulus-use'
import CodeMirror from './functions/code_mirror6_wrapper'

export default class extends Controller {
  static get values() {
    return {
      mode: String, // ex) text/javascript, text/x-sass
      hasCodeMirrorAttached: { type: Boolean, default: false },
      classToAdd: { type: String, default: 'd-block' },
      readOnly: { type: Boolean, default: true },
    }
  }
  static get targets() {
    return ['textarea', 'icon']
  }

  connect() {
    // console.debug('[chat][js] connect', this.identifier)
    this.element.$formItemTextarea = this
    useIntersection(this)
  }

  appear(_entry) {
    if (this.hasCodeMirrorAttachedValue) return
    // console.debug('[chat][js] interaction#appear', this.identifier, _entry)

    // NOTE: CodeMirror elements remained after copy operation, so remove beforehand.
    const codeMirror = this.element.querySelector('.cm-editor')
    if (!this.hasCodeMirrorAttachedValue && codeMirror) codeMirror.remove()

    const onChange = ({ value }) => {
      if (this.readOnlyValue) return
      // console.debug('[chat][js][cm]', 'change-' + this.modeValue, { value: value })
      // NOTE: dispatch a custom event user--schema-editor--form-item-textarea:change-text/javascript
      // About dispacth() funciton : https://stimulus.hotwired.dev/reference/controllers#cross-controller-coordination-with-events
      const mode = this._getMode() // text/javascript -> javascript, text/x-scss -> scss
      if (['javascript', 'scss', 'json', 'liquid'].includes(mode)) {
        this.dispatch('change-textarea-code', { detail: { mode: mode, code: value } }) // change-text/javascript
      }
    }
    const cm = new CodeMirror({
      textarea: this.textareaTarget,
      mode: this._getMode(),
      isReadOnly: this.readOnlyValue,
      onChange: onChange,
    })
    cm.applyTo(_viewDom => {
      // viewDom.classList.add(this.classToAddValue)
    })
    this.cm = cm // Attach CodeMirror instance to this
    this.hasCodeMirrorAttachedValue = true

    // NOTE: Trigger onChange when the textarea is loaded
    // onChange({ value: cm._instance.getValue() })
  }

  // disappear(_entry) {
  //   // console.debug('[chat][js] interaction#disappear', this.identifier, _entry)
  // }

  // Normalize the namings for mode
  //   - text/javascript -> javascript
  //   - text/x-scss     -> scss
  //   - liquid          -> liquid
  _getMode() {
    return this.modeValue.split('/').pop().replace('x-', '')
  }

  toggleReadOnly() {
    this.readOnlyValue = !this.readOnlyValue
    this.cm.toggleReadOnly()
    // console.debug('[chat][js] toggle cm.', 'readOnly=', this.cm.isReadOnly)
  }

  // Called after:
  //  - the controller is initialize
  //  - any time its associated data attribute changes
  readOnlyValueChanged() {
    if (!this.hasIconTarget) return

    if (this.readOnlyValue) {
      this.iconTarget.innerText = 'lock'
    } else {
      this.iconTarget.innerText = 'edit'
    }
  }

  // $('#scenarios-product-messages___132d3301bd-forms___4d9947ad8a-onUpdate').get()[0].$formItemTextarea.toggleReadOnly()
}
