import { page, dateRangePicker } from '../init'

$(function() {
  if (page.controllerPath() === 'admin/users') {
    if (['edit', 'update'].includes(page.action())) {
      console.debug('[JS] daterangepicker')
      dateRangePicker.triggerSingleDatePicker('#users-deleted_at', { updatedSelector: '#datetime-deleted_at', timePicker: true })
      dateRangePicker.triggerSingleDatePicker('#users-started_at', { updatedSelector: '#datetime-started_at', timePicker: true })
      dateRangePicker.triggerSingleDatePicker('#users-suspended_at', { updatedSelector: '#datetime-suspended_at', timePicker: true })
    }
    if (['new', 'create'].includes(page.action())) {
      console.debug('[JS] daterangepicker')
      dateRangePicker.triggerSingleDatePicker('#users-started_at', { updatedSelector: '#datetime-started_at', timePicker: true })
    }
  }
})
