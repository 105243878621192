import Sortable from 'sortablejs'

class Sortable_ {
  static get defaultOptions() {
    return {
      handle: '.sortable-handle',
      ghostClass: 'bg-secondary',
      animation: 150,
      fallbackOnBody: true,
      swapThreshold: 0.65,
      filter: '.ignore-sortable', // NOTE: selects are not open due to backwards compatibility of `preventOnFilter` condition.
      preventOnFilter: false,     //       https://github.com/SortableJS/Sortable/issues/1537
    }
  }
  applyTo(element, options = {}) {
    return element.querySelectorAll("[data-sort='sortable']").forEach(element => {
      Sortable.create(element, { ...Sortable_.defaultOptions, ...options })
    })
  }
}
export const sortable = new Sortable_

class Popover_ {
  static get defaultOptions() {
    return {
      container: 'body',
      boundary: 'window',
      delay: { show: 100, hide: 50 },
      // trigger: 'hover', // click | hover | focus | manual
    }
  }
  applyTo(element, options = {}) {
    return $(element).find('[data-toggle="popover"]').popover({ ...Popover_.defaultOptions, ...options })
  }
  applyToAll(options = {}) {
    return $('[data-toggle="popover"]').popover({ ...Popover_.defaultOptions, ...options })
  }
}
export const popover = new Popover_
