import { Application } from "@hotwired/stimulus"
import { definitionsFromContext } from '@hotwired/stimulus-webpack-helpers'

const application = Application.start()

// stimulus webpack helper
const context = require.context('.', true, /_controller\.js$/)
application.load(definitionsFromContext(context))

// Configure Stimulus development experience
application.debug = false
window.Stimulus   = application

export { application }
