import { page } from '../../../init'

$(function() {
  if (page.controllerPath() === 'user/profiles/builds/preview') {
    // NOTE: Set chat settings by gon.chat_settings
    page.afterChatLoaded(function() {
      for (const chat_setting_key in gon.chat_settings) {
        console.debug('[chat][settings]', chat_setting_key, gon.chat_settings[chat_setting_key])
        window.chat.settings[chat_setting_key] = gon.chat_settings[chat_setting_key]
      }
    })

    $('input[name="toggle_force_api_to_work"]').on('change', function(event) {
      const isChecked = $(event.currentTarget).prop('checked')
      // console.debug('[chat][toggle_force_api_to_work] change', isChecked, event)

      const currentUrl = new URL(window.location.href)
      if (isChecked) {
        currentUrl.searchParams.set('chat_settings_forceApiToWork', true)
      } else {
        currentUrl.searchParams.delete('chat_settings_forceApiToWork')
      }
      window.location.href = currentUrl.href
    })
  }
})
