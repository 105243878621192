
export function randomString(length = 10) {
  // dec2hex :: Integer -> String
  // i.e. 0-255 -> '00'-'ff'
  function dec2hex (dec) {
    return dec.toString(16).padStart(2, "0")
  }
  // generateId :: Integer -> String
  var arr = new Uint8Array((length || 40) / 2)
  window.crypto.getRandomValues(arr)
  return Array.from(arr, dec2hex).join('')
}
// export function randomStringOld(length = 10) { return Math.random().toString(36).substring(length) }
// https://stackoverflow.com/questions/1349404/generate-random-string-characters-in-javascript

/**
 * @param {String} HTML representing a single element
 * @return {Element}
 */
export function htmlToElement(html) {
  const template = document.createElement('template')
  html = html.trim() // Never return a text node of whitespace as the result
  template.innerHTML = html
  return template.content.firstElementChild
}
// https://stackoverflow.com/questions/494143/creating-a-new-dom-element-from-an-html-string-using-built-in-dom-methods-or-pro/35385518#35385518

export function csrfToken() { return document.querySelector("[name='csrf-token']").content }
// https://bloggie.io/@kinopyo/sending-non-get-requests-with-fetch-javascript-api-in-rails

// @Deprecated: Not Used
export function toHtmlEntities(str) {
  return String(str).replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;');
}
// https://stackoverflow.com/questions/14129953/how-to-encode-a-string-in-javascript-for-displaying-in-html

// speed: slower, slow, fast, faster
export function animate(element, animation, speed = '') {
  return new Promise((resolve) => {
    let classes = ['animate__animated', 'animate__' + animation]
    if (speed) classes.push('animate__' + speed)

    element.classList.add(...classes)
    element.addEventListener('animationend', () => {
      element.classList.remove(...classes)
      resolve('animation ended')
    }, { once: true })
  })
}
