import { page } from '../../init'

$(function() {
  // https://www.driftingruby.com/episodes/page-specific-javascript-in-ruby-on-rails
  if (page.controllerPath() === 'user/profile_groups/publishes') {
    console.debug('[JS] clipboard')
    const ClipboardJS = require('clipboard')
    const clipboard = new ClipboardJS('.clip-btn')
    // clipboard.on('success', function(e) {
    //   console.log(e)
    // })
    clipboard.on('error', function(e) {
      console.error(e)
    })
  }
})
