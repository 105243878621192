import { Controller } from '@hotwired/stimulus'
import { animate } from './user/schema_editor/functions/util'

export default class extends Controller {
  static get targets() {
    return ['subject', 'view']
  }

  connect() {
    // console.debug('[chat][js] connect', this.identifier)
    // this.element.$counter = this
  }

  update() {
    // NOTE: setTimeout is for a remove event to wait until the element is removed.
    setTimeout(() => {
      // console.debug(this.viewTarget, this.subjectTarget, this.viewTarget.innerText, this.subjectTarget.childElementCount)
      this.viewTarget.innerText = '(' + this.subjectTarget.childElementCount + ')'

      // NOTE: animate.css with span tag
      //       https://stackoverflow.com/questions/65435099/animate-css-is-not-working-with-some-classes-in-javascript
      animate(this.viewTarget, 'heartBeat', 'slower')
    }, 250)
  }
}
