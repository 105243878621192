import { Controller } from '@hotwired/stimulus'
import { sortable, popover } from './functions/wrapper'
import { randomString, htmlToElement, animate } from './functions/util'

export default class extends Controller {
  connect() {
    // console.debug('[chat][js] connect', this.identifier)
    this.element.$operations = this
  }
  // disconnect() { console.debug('[chat][js] disconnect', this.identifier) }

  moveItemDown() {
    if (!gon.template_uuid) { console.error('readonly'); return; }
    // parentNode.insertBefore(newNode, referenceNode)
    if (this.element.nextElementSibling) {
      this.element.parentNode.insertBefore(this.element.nextElementSibling, this.element)
    } else {
      this.element.parentNode.insertBefore(this.element, this.element.parentElement.firstElementChild)
    }
  }

  moveItemUp() {
    if (!gon.template_uuid) { console.error('readonly'); return; }
    // parentNode.insertBefore(newNode, referenceNode)
    this.element.parentNode.insertBefore(this.element, this.element.previousElementSibling)
  }

  collapseAll() {
    this.element.querySelectorAll('.collapse').forEach(el => {
      if (gon.expand_exceptions.includes(el.getAttribute('data'))) return // continue
      el.classList.remove('show')
    })
    this.element.querySelectorAll('[aria-expanded="true"]').forEach(el => {
      el.setAttribute('aria-expanded', false)
    })
  }

  expandAll() {
    this.element.querySelectorAll('.collapse').forEach(el => {
      el.classList.add('show')
    })
    this.element.querySelectorAll('[aria-expanded="false"]').forEach(el => {
      el.setAttribute('aria-expanded', true)
    })
  }

  removeItem(event) {
    if (!gon.template_uuid) { console.error('readonly'); return false; }
    if (window.confirm(gon.translations.delete_confirm)) {
      // $(this.element.querySelectorAll('[data-toggle="tooltip"]')).tooltip('hide') // all tooltips within the controller's element
      $(event.currentTarget).tooltip('hide')

      animate(this.element, 'fadeOut', 'slow').then(() => {
        // NOTE: Need to put thie line above this.element.remove() as this element emits the event and it results in no event without the element to dispatch.
        this.dispatch('remove')
        this.element.remove()
      })
      return true
    } else {
      return false
    }
  }

  copyItem({ params }) {
    const formUuid = params.formUuid
    const newFormUuid = randomString()
    // console.debug('[chat][js]', formUuid, newFormUuid)

    const replacedHtml = this.element.outerHTML.replaceAll(formUuid, newFormUuid)
    const replacedHtmlElement = htmlToElement(replacedHtml)

    sortable.applyTo(replacedHtmlElement)
    popover.applyTo(replacedHtmlElement)

    this.element.parentNode.append(replacedHtmlElement)
    animate(replacedHtmlElement, 'fadeIn', 'slower')
  }
}
