import { Controller } from '@hotwired/stimulus'
import { useIntersection } from 'stimulus-use'
import Pickr from '@simonwep/pickr'

export default class extends Controller {
  static get values() {
    return {
      hasAttached: { type: Boolean, default: false },
    }
  }
  static get targets() {
    return ['input', 'preview', 'revert']
  }

  connect() {
    // console.debug('[chat][js] connect', this.identifier)
    // this.element.$colorPicker = this
    useIntersection(this)

    // Set i18n
    if (gon.color_pickr) {
      sessionStorage.setItem('gon.color_pickr', JSON.stringify(gon.color_pickr))
    } else {
      gon.color_pickr = JSON.parse(sessionStorage.getItem('gon.color_pickr'))
    }
    this.gon_color_pickr = gon.color_pickr

    this.originalColorValue = this.inputTarget.value
  }

  appear(_entry) {
    if (this.hasAttachedValue) return
    console.debug('[chat][js] interaction#appear', this.identifier, _entry.time) // _entry

    this._createColorPicker(this.inputTarget)
    this.revertTarget.addEventListener('click', (_event) => {
      const message = this.gon_color_pickr?.revert?.confirm || 'Revert to the default color value?'
      if (confirm(message) == true) {
        this.inputTarget.value = this.originalColorValue
        this._updatePreviewColorBox()
        this.revertTarget.style.visibility = 'hidden'
      }
    })
    this.hasAttachedValue = true
  }

  _createColorPicker(targetElement) {
    targetElement.addEventListener('change', (_event) => {
      this._updatePreviewColorBox()
      this.revertTarget.style.visibility = 'visible'
    })
    return Pickr.create({
      el: targetElement,
      theme: 'classic', // 'classic', or 'monolith', or 'nano'
      useAsButton: true,
      lockOpacity: false,
      default: this.inputTarget.value,
      swatches: [
        '#027be3',
        '#ffffff',
        '#f8e0e6',
        '#e0e0e0',
        '#ffc107',
        'orange',
        '#f2c037',
        'black',
        '#31ccec66',
        '#eeeeee80',
      ],
      components: {
        // Main components
        preview: true,
        opacity: true,
        hue: true,
        // Input / output Options
        interaction: {
          hex: true,
          rgba: true,
          hsla: false,
          hsva: false,
          cmyk: false,

          input: true,
          cancel: false,
          clear: false,
          save: true
        }
      },
      i18n: this.gon_color_pickr.i18n || {},
    }).on('init', _pickr => {
      this._updatePreviewColorBox()
    }).on('show', (_color, pickr) => {
      pickr.setColor(targetElement.value, true)
    }).on('cancel', pickr => {
      pickr.hide()
    }).on('save', (color, pickr) => {
      targetElement.value = color.toHEXA().toString(0)
      this._updatePreviewColorBox()
      this.revertTarget.style.visibility = 'visible'
      pickr.hide()
    })
  }

  _updatePreviewColorBox() {
    this.previewTarget.style.backgroundColor = this.inputTarget.value
  }
}
