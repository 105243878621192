import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static get values() {
    return {
      url: String,
    }
  }

  connect() {
    console.debug('[chat][js] connect', this.identifier)
    // this.element['$scenarios'] = this
  }
  // disconnect() { console.debug('[chat][js]', this.disconnect.name, this.identifier) }

  preview() {
    console.debug('chat_preview', this.urlValue)
    this._postByAjax()
  }

  // Content-Type: application/x-www-form-urlencoded; charset=UTF-8
  //
  // dataType: what kind of response to expect
  // contentType: HTTP header sent to the server, specifying a particular format.
  _postByAjax() {
    $.ajax({
      type: 'PUT',
      url: this.urlValue,
      // data: $('form').serialize(),
      dataType: 'json', // json, text
      success: function(data) {
        // console.degbug('[chat] update-chat-preview', data)
        sessionStorage.setItem('chat:edit:settings', '__q_objt|' + JSON.stringify(data.settings))
        sessionStorage.setItem('chat:edit:scenarios', '__q_objt|' + JSON.stringify(data.scenarios))
        if (data.forms) sessionStorage.setItem('chat:edit:forms', '__q_objt|' + JSON.stringify(data.forms))
        if (data.js_keys) sessionStorage.setItem('chat:edit:js_keys', '__q_objt|' + JSON.stringify(data.js_keys))
        $(document).trigger('chat:reload:mode:preview')
      },
      error: function(data, textStatus, _errorThrown) {
        if (data.status === 401) {
          const errorText = data.responseJSON.error.text
          console.warn(`[chat][js] ${data.status}: ${data.statusText}`, errorText, data, textStatus)
          window.alert(errorText) // Unauthorized
        } else {
          console.error(`[chat][js] ${data.status}: ${data.statusText}\n${data.responseText}`, data, textStatus)
          const text = data.responseText.split("\n")?.slice(0, 5)
          window.alert(`${data.status}: ${data.statusText}\n==============\n${text}`) // Internal Server Error.
        }
        // throw errorThrown
      }
    });
  }
}
