import { page, bootstrapSelect, dateRangePicker } from '../init'

$(function() {
  if (page.controllerPath() === 'user/builds') {
    console.debug('[JS] daterangepicker')
    dateRangePicker.trigger('#daterange-started_at', 'last_30_days')

    console.debug('[JS] bootstrap-select')
    bootstrapSelect.trigger({showSubtext: true})
  }
})
