import { Controller } from '@hotwired/stimulus'
import { useIntersection } from 'stimulus-use'

export default class extends Controller {
  static get targets() {
    return ['viewInput', 'hiddenInput']
  }

  connect() {
    // console.debug('[chat][js] connect', this.identifier)
    // this.element.$formItemToggle = this
    useIntersection(this)
  }

  appear(_entry) {
    // console.debug('[chat][js] interaction#appear', this.identifier, _entry)
    $(this.viewInputTarget).bootstrapToggle({
      size: 'mini',
      on: ' ',
      off: ' ',
      offstyle: 'secondary',
      style: 'ios',
    })
    $(this.viewInputTarget).on('change', () => {
      // console.log($(this.viewInputTarget).prop('checked'))
      $(this.viewInputTarget).val($(this.viewInputTarget).prop('checked'))
      $(this.hiddenInputTarget).val($(this.viewInputTarget).prop('checked'))
    })
  }

  // disappear(_entry) {
  //   // console.debug('[chat][js] interaction#disappear', this.identifier, _entry)
  // }
}
