import consumer from './consumer'

consumer.subscriptions.create({ channel: 'ProfileBuildChannel' }, {
  connected() {},
  // Called when the subscription is ready for use on the server

  disconnected() {},
  // Called when the subscription has been terminated by the server

  received(data) {
    // Called when there's incoming data on the websocket for this channel
    // console.debug('[chat] profile_build_channel',
    //   data['profile_build_path'], location.pathname,
    //   data,
    // )
    if (['completed', 'failed'].includes(data['state'])) {
      if (data['profile_build_path'] === location.pathname) location.reload()
      this.notify(data)
    } else {
      if (data['profile_build_path'] === location.pathname) this.replace(data)
    }
  },

  replace(data) {
    $(`#tr_${data['build_uuid']}`).replaceWith(data['row'])
  },

  notify(data) {
    const notify = data['notify']
    const template = `
    <div data-notify="container" class="col-sm-11 col-md-6 col-lg-4 alert alert-{0}" role="alert">
        <div>
          <span data-notify="icon"></span>
          <strong data-notify="title">{1}</strong>
          <button type="button" aria-hidden="true" class="close" data-notify="dismiss">&times;</button>
        </div>
        <hr style="margin: 10px 0;"/>
        <div>
        <span data-notify="message">{2}</span>
        </div>
      <div class="progress" data-notify="progressbar">
        <div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>
      </div>
      <a href="{3}" target="{4}" data-notify="url"></a>
    </div>
    `
    $.notify({
      title: notify['title'],
      message: notify['message'],
      url: notify['url'],
    }, {
      type: notify['type'],
      delay: 30000,
      url_target: '_self',
      placement: {
        from: 'bottom',
        align: 'right',
      },
      animate: {
        enter: 'animate__animated animate__fadeInRight',
        exit: 'animate__animated animate__fadeOutRight',
      },
      mouse_over: 'pause',
      template: template,
    })
  },

  // Calls `ProfileBuildChannel#test(data)` on the server.
  test() {
    const data = { part1: 'aaa' }
    this.perform('test', data)
  }
})
