import { Controller } from '@hotwired/stimulus'
import { sortable, popover } from './functions/wrapper'
import { csrfToken, htmlToElement, animate } from './functions/util'

const ALPHA_NUM_UNDER_SCORE_REGEXP = /^[a-zA-Z0-9_]{4,15}$/

export default class extends Controller {
  static get targets() {
    return ['list', 'scenarioKey', 'scenarioName', 'scenarioType']
  }

  connect() {
    console.debug('[chat][js]', this.connect.name, this.identifier)
    this.element['$scenarios'] = this
  }
  disconnect() { console.debug('[chat][js]', this.disconnect.name, this.identifier) }

  addFreeScenario() { // {{{
    if (!gon.template_uuid) { console.error('readonly'); return; }

    const targetListElement = this.listTarget
    const scenarioType = this.scenarioTypeTarget.value
    const scenarioKey = this.scenarioKeyTarget.value
    const scenarioName = this.scenarioNameTarget.value
    this._validate(scenarioType, scenarioKey, scenarioName)

    const data = {
      scenario_key: scenarioKey,
      scenario_name: scenarioName,
      scenario_type: scenarioType.substring(1), // NOTE: Remove 1st _ charactor (_greeting)
      all_scenario_options: this._makeScenarioOptions(targetListElement, scenarioKey)
    }

    this._fetchScenario(targetListElement, data, 'render_free_scenario', () => {
      $('#add-free-scenario').modal('hide') // Close the caller modal after adding a scenario
      $(this.scenarioTypeTarget).selectpicker('val', '') // the bootstrap-select ui will not refresh when calling directly. e.g. this.scenarioTypeTarget.value = ''
      this.scenarioKeyTarget.value = ''
      this.scenarioNameTarget.value = ''
    })
  } // }}}

  _validate(scenarioType, scenarioKey, scenarioName) { // {{{
    let messages = []

    if (!scenarioType) {
      messages.push(gon.translations.free_scenario.validations.scenario_type.empty)
    }

    if (!scenarioKey) {
      messages.push(gon.translations.free_scenario.validations.scenario_key.empty)
    }
    if (!ALPHA_NUM_UNDER_SCORE_REGEXP.test(scenarioKey)) {
      messages.push(gon.translations.free_scenario.validations.scenario_key.pattern)
    }
    if (scenarioKey.length > 15) {
      messages.push(gon.translations.free_scenario.validations.scenario_key.length)
    }

    if (!scenarioName) {
      messages.push(gon.translations.free_scenario.validations.scenario_name.empty)
    }
    if (scenarioName.length > 15) {
      messages.push(gon.translations.free_scenario.validations.scenario_name.length)
    }

    if (messages.length) {
      alert(messages.join("\n"))
      throw messages
    }
  } // }}}

  add({ params: { parentModelKey, modelKey, scenarioKeyToAdd } }) { // {{{
    if (!gon.template_uuid) { console.error('readonly'); return; }

    const targetListElement = this.listTarget
    const scenarioOptions = this._makeScenarioOptions(targetListElement, scenarioKeyToAdd)
    // console.debug(allScenarioKeys, scenarioKeys, scenarioNames, scenarioOptions)

    const data = {
      parent_model_key: parentModelKey,
      model_key: modelKey,
      all_scenario_options: scenarioOptions,
    }
    this._fetchScenario(targetListElement, data, 'render_scenario_data_container')
  } // }}}

  _fetchScenario(targetListElement, data, endpoint, callback = () => {}) { // {{{
    fetch('/user/api/templates/schema_editor/' + endpoint, {
      method: 'POST',
      mode: 'same-origin', // no-cors, *cors, same-origin
      // cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      // credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        'X-CSRF-Token': csrfToken(),
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data),
    }).then(response => {
      if (!response.ok) {
        if (response.status === 401) {
          response.json().then(function(json) {
            console.error('[chat][js]', json.error.text)
            window.alert(json.error.text) // Unauthorized
          })
        } else {
          window.alert('Internal Server Error.')
        }
        throw response;
      }
      return response.text()
    }).then((html) => {
      const newScenarioTemplate = htmlToElement(html)

      const key = newScenarioTemplate.querySelector('input[data-schema-key="scenarios:key"]').value
      const name = newScenarioTemplate.querySelector('input[data-schema-key="scenarios:name"]').value

      targetListElement.querySelectorAll('select[data-schema-key="scenarios:next"]').forEach(selectElement => {
        const option = document.createElement('option')
        option.value = key
        option.text = this._makeScenarioNextOptionValue(key, name)
        selectElement.add(option, null) // https://developer.mozilla.org/en-US/docs/Web/API/HTMLSelectElement/add
      })

      targetListElement.prepend(newScenarioTemplate)
      animate(newScenarioTemplate, 'fadeIn', 'slower')

      // Trigger sortable custom event (defined in app/javascript/src/user/profiles.js)
      sortable.applyTo(newScenarioTemplate)
      popover.applyTo(newScenarioTemplate)

      callback()
    }).catch(error => {
      console.error('[chat][js] error.', error)
    })
  } // }}}

  _makeScenarioOptions(targetListElement, scenarioKeyToAdd) { // {{{
    const allScenarioKeys = Array.from(targetListElement.querySelectorAll('input[data-schema-key="scenarios:key"]'), el => el.value)

    // Check scenario key duplication
    if (allScenarioKeys.includes(scenarioKeyToAdd)) {
      window.alert(gon.translations.same_scenario)
      throw gon.translations.same_scenario
    }

    const scenarioKeys = allScenarioKeys.filter(key => key !== 'start')
    const scenarioNames = Array.from(targetListElement.querySelectorAll('input[data-schema-key="scenarios:name"]'), el => el.value).filter(key => key !== '立ち上げ')
    if (scenarioKeys.length !== scenarioNames.length) {
      window.alert('シナリオ編集の状態が異常です。ページを再読み込みしてやり直してください。')
      throw `The number of scenario keys and names is different. keys=${scenarioKeys.length}, names=${scenarioNames.length}`
    }
    const scenarioOptions = scenarioKeys.map((key, i) => { return [this._makeScenarioNextOptionValue(key, scenarioNames[i]), key] })
    scenarioOptions.unshift(['使用しない', '-'])
    scenarioOptions.push(['end （終了）', 'end'])
    return scenarioOptions
  } // }}}

  _makeScenarioNextOptionValue(key, name) { return key + ' （' + name + '）' }
}
